import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import styles from "./login.module.scss";

const apiUrl = process.env.REACT_APP_API;

function Login() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [psw, setPsw] = useState(null);
  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(`${apiUrl}login/`, {
      method: "POST",
      body: JSON.stringify({ user, psw }),
    })
      .then((resp) => resp.json())
      .then((json) => {
        if (json.errno) {
          console.error(json);
        } else {
          localStorage.setItem("token", json.token);
          sessionStorage.setItem("isLogin", true);
          navigate("/dashboard");
        }
      });
  };

  return (
    <>
      <Header />
      <div className={styles.login}>
        <form action="" onSubmit={handleSubmit}>
          <div className={styles.field}>
            <label htmlFor="username">Username</label>
            <input
              onKeyUp={(evt) => setUser(evt.target.value)}
              type="text"
              id="username"
              name="username"
            />
          </div>
          <div className={styles.field}>
            <label htmlFor="password">Password</label>
            <input
              onKeyUp={(evt) => setPsw(evt.target.value)}
              type="password"
              id="password"
              name="password"
            />
          </div>
          <div className={styles.btn}>
            <button>Ingresar</button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Login;
