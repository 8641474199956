import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLogin, removeSession } from "../hooks/useLogin";
import Header from "../components/Header";
import styles from "./products.module.scss";

const apiUrl = process.env.REACT_APP_API;

const WoodPriceInput = ({ price }) => {
  const [total, setTotal] = useState(price.price);
  const { token } = useLogin();

  const changePrice = (id) => {
    fetch(`${apiUrl}price/`, {
      method: "PATCH",
      body: JSON.stringify({ id, price: total }),
      headers: { Authorization: token },
    })
      .then((resp) => resp.json())
      .then((json) => {});
  };

  return (
    <>
      <input
        type="text"
        onChange={(evt) => setTotal(evt.target.value)}
        value={total}
      />
      <button onClick={(evt) => changePrice(price.id)}>SAVE</button>
    </>
  );
};

const ProductRow = ({ product }) => {
  return (
    <div className={styles.row}>
      <div className={`${styles.col} ${styles.small}`}>{product.id}</div>
      <div className={styles.col}>{product.model}</div>
      <div className={`${styles.col} ${styles.mid}`}>
        {product.costume_options.woodTypes.items.map((wood) => (
          <WoodPriceInput key={wood.price.id} price={wood.price} />
        ))}
      </div>
    </div>
  );
};

function Dashboard() {
  const navigate = useNavigate();
  const { error, user, token } = useLogin();
  const [products, setProducts] = useState(null);

  useEffect(() => {
    if (error?.errno) removeSession(navigate);

    if (user) {
      fetch(`${apiUrl}products/`, {
        method: "GET",
        headers: { Authorization: token },
      })
        .then((resp) => resp.json())
        .then((json) => {
          if (json.errno) {
            console.error(json);
          } else {
            setProducts(json);
          }
        });
    }
  }, [error, user, token, navigate]);

  return (
    <>
      <Header showLinks />
      <div className={styles.container}>
        {products &&
          products.items.map((product) => (
            <ProductRow key={product.id} product={product} />
          ))}
      </div>
    </>
  );
}

export default Dashboard;
