import { useState, useEffect } from "react";

const apiUrl = process.env.REACT_APP_API;

export function removeSession(navigate) {
  localStorage.removeItem("token");
  sessionStorage.removeItem("isLogin");
  navigate("/");
}

export function useLogin() {
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (sessionStorage.getItem("isLogin")) {
      setUser(true);
      return;
    }

    if (!token) removeSession();

    fetch(`${apiUrl}login/`, {
      method: "GET",
      headers: { Authorization: token },
    })
      .then((resp) => resp.json())
      .then((json) => {
        if (json.errno) {
          setError(json);
          setUser(null);
        } else {
          setUser(json);
          setError(null);
          sessionStorage.setItem("isLogin", true);
        }
      });
  }, [token]);

  return { error, user, token };
}
