import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useLogin, removeSession } from "../hooks/useLogin";
import styles from "./dashboard.module.scss";
import Header from "../components/Header";

const apiUrl = process.env.REACT_APP_API;

const OrderRow = ({ order }) => {
  return (
    <div className={styles.row}>
      <div className={[styles.col]}>{order.buyer.name}</div>
      <div className={[styles.col]}>{order.psm.id}</div>
      <div className={`${styles.col} ${styles.small}`}>
        {order.created_at.split(/\s+/)[0]}
      </div>
      <div className={`${styles.col} ${styles.small}`}>
        {order.production_type}
      </div>
      <div className={`${styles.col} ${styles.small}`}>
        {order.status.status}
      </div>
      <div className={`${styles.col} ${styles.small}`}>${order.total}</div>
      <div className={`${styles.col} ${styles.small}`}>
        <Link to={`/order/${order.id}`}>Ver</Link>
      </div>
    </div>
  );
};

function Dashboard() {
  const navigate = useNavigate();
  const [orders, setOrders] = useState(null);
  const { error, user, token } = useLogin();

  useEffect(() => {
    if (error?.errno) removeSession(navigate);

    if (user) {
      fetch(`${apiUrl}orders/`, {
        method: "GET",
        headers: { Authorization: token },
      })
        .then((resp) => resp.json())
        .then((json) => {
          if (json.errno) {
            console.error(json);
          } else {
            console.log(json);
            json.items = json.items.reverse();
            setOrders(json);
          }
        });
    }
  }, [error, user, navigate, token]);
  return (
    <>
      <Header showLinks />
      <div className={styles.container}>
        <div className={`${styles.row} ${styles.titles}`}>
          <div className={[styles.col]}>Buyer Name</div>
          <div className={[styles.col]}>Order Id</div>
          <div className={`${styles.col} ${styles.small}`}>Date</div>
          <div className={`${styles.col} ${styles.small}`}>P.Time</div>
          <div className={`${styles.col} ${styles.small}`}>Status</div>
          <div className={`${styles.col} ${styles.small}`}>Total</div>
          <div className={`${styles.col} ${styles.small}`}> </div>
        </div>
        {orders &&
          orders.items.map((order) => (
            <OrderRow key={order.id} order={order} />
          ))}
      </div>
    </>
  );
}

export default Dashboard;
