import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLogin, removeSession } from "../hooks/useLogin";
import Header from "../components/Header";

import styles from "./order.module.scss";

const apiUrl = process.env.REACT_APP_API;

const Item = ({ item }) => {
  return (
    <article className={styles.product}>
      <div>
        <span>Model:</span> {item.product.model}
      </div>
      <div>
        <span>Qty:</span> {item.quantity}
      </div>
      <div>
        <span>Price p/u:</span> ${item.price.price}
      </div>
      <div>
        <span>Total:</span> ${item.total}
      </div>
      <div className={styles.details}>
        <h4>Details:</h4>
        <div>
          <span>Wood:</span> {item.details.wood.name}
        </div>
        <div>
          <span>Barrel:</span> {item.details.barrel.name} /{" "}
          {item.details.barrel_finish.name}
        </div>
        <div>
          <span>Grip:</span> {item.details.grip.name} /{" "}
          {item.details.grip_finish.name}
        </div>
        <div>
          <span>Ring:</span> {item.details.ring.name}
        </div>
        <div>
          <span>Logo:</span> {item.details.logo.name}
        </div>
        <div>
          <span>Size:</span> {item.details.size.value}{" "}
          {item.details.size.measurement}
        </div>
        <div>
          <span>Weight:</span> {item.details.weight.value}{" "}
          {item.details.weight.measurement}
        </div>
        <div>
          <span>Cup:</span> {item.details.cup.name}
        </div>
        <div>
          <span>Engraving: </span>
          {item.details.engraving}
        </div>
      </div>
    </article>
  );
};

function Order() {
  const navigate = useNavigate();
  const id = useParams().orderId;
  const { error, user, token } = useLogin();
  const [orderData, setOrderData] = useState();

  useEffect(() => {
    if (error?.errno) removeSession(navigate);

    if (user) {
      fetch(`${apiUrl}order/?id=${id}`, {
        method: "GET",
        headers: { Authorization: token },
      })
        .then((resp) => resp.json())
        .then((json) => {
          if (json.errno) {
            // console.error(json);
          } else {
            setOrderData(json);
          }
        });
    }
  }, [error, user, id, token, navigate]);

  return (
    <>
      <Header showLinks />
      <main className={styles.container}>
        {orderData && (
          <>
            <section>
              <h1>ORDER INFORMATION</h1>
              <div className={styles.info}>
                <article>
                  <h2>General Information</h2>
                  <div>
                    <span>ID:</span> {orderData.id}
                  </div>
                  <span>PSM ID:</span> {orderData.psm.id}
                  <div>
                    <span>Total:</span> ${orderData.total}
                  </div>
                  <div>
                    <span>Production Type:</span> {orderData.production_type}
                  </div>
                  <div>
                    <span>Status:</span> {orderData.status.status}
                  </div>
                  <div>
                    <span>Created At:</span> {orderData.created_at}
                  </div>
                </article>
                <article>
                  <h2>Buyer Information</h2>
                  <div>
                    <span>Buyer name:</span> {orderData.buyer.name}
                  </div>
                  <div>
                    <span>Buyer email:</span> {orderData.buyer.email}
                  </div>
                  <div>
                    <span>Buyer phone:</span> {orderData.buyer.phone}
                  </div>
                  <div className={styles.info}>
                    <div className={styles.address}>
                      <h3>Address:</h3>
                      <div>
                        <span>Street: </span>
                        {orderData.buyer.address.street}
                      </div>
                      <div>
                        <span>City:</span> {orderData.buyer.address.city}
                      </div>
                      <div>
                        <span>State: </span>
                        {orderData.buyer.address.state}
                      </div>
                      <div>
                        <span>Zip Code:</span> {orderData.buyer.address.zipcode}
                      </div>
                      <div>
                        <span>Country:</span> {orderData.buyer.address.country}
                      </div>
                    </div>
                    <div className={styles.shipping}>
                      <h3>Shipping</h3>
                      <div>
                        <span>Type:</span> {orderData.shipping.type}
                      </div>
                      <div>
                        <span>Total:</span> ${orderData.shipping.total}
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </section>
            <section>
              <h1>PRODUCTS</h1>
              <div className={styles.itemList}>
                {orderData.items &&
                  orderData.items.items.map((item) => (
                    <Item key={item.id} item={item} />
                  ))}
              </div>
            </section>
          </>
        )}
      </main>
    </>
  );
}

export default Order;
