import { Link } from "react-router-dom";
import styles from "./header.module.scss";

export default function Header({ showLinks }) {
  return (
    <header className={styles.header}>
      <figure className={styles.logo}>
        <img src="/images/horizontal-main-logo-white.png" alt="Main logo" />
      </figure>
      {showLinks && (
        <nav className={styles.nav}>
          <Link to="/dashboard">Orders</Link>
          <Link to="/products">Products</Link>
        </nav>
      )}
    </header>
  );
}
